import React from "react"

export const Screen = () => (
  <svg width="20" height="20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.38708 2.18165c0-1.09797.89009-1.988046 1.98806-1.988046h9.44326c1.098 0 1.988.890076 1.988 1.988056v9.44324c0 1.098-.89 1.9881-1.988 1.9881H8.37514c-1.09797 0-1.98806-.8901-1.98806-1.9881V2.18165Zm11.43132.00001-9.44326-.00001v9.44325h9.44326V2.18166Z"
      fill="#000"
    />
    <path
      d="M1.22583 8.40681c0-.54531.44206-.98738.98738-.98738h9.38009c.5453 0 .9874.44207.9874.98738v9.38009c0 .5453-.4421.9874-.9874.9874H2.21321c-.54532 0-.98738-.4421-.98738-.9874V8.40681Z"
      fill="#EEEFF8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.193542 8.3752c0-1.09797.890078-1.98805 1.988058-1.98805h9.4432c1.098 0 1.9881.89008 1.9881 1.98805v9.4432c0 1.098-.8901 1.9881-1.9881 1.9881H2.1816c-1.09798 0-1.988058-.8901-1.988058-1.9881V8.3752Zm11.431258 0H2.1816v9.4432h9.4432V8.3752Z"
      fill="#000"
    />
  </svg>
)
