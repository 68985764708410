import React from "react"

export const Atom = () => (
  <svg
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 22 22"
  >
    <g fill="#000" clipPath="url(#clip0)">
      <path d="M20 1.995C18.227.22 14.707.75 11 3.033 7.294.75 3.773.221 1.999 1.995.224 3.77.754 7.29 3.037 10.995.754 14.703.224 18.223 1.999 19.998a3.987 3.987 0 002.918 1.063A12.263 12.263 0 0011 18.964a12.261 12.261 0 006.083 2.096A3.988 3.988 0 0020 19.997c1.774-1.775 1.245-5.295-1.039-9.001 2.284-3.706 2.813-7.226 1.039-9zm-2.949.365c.705-.048 1.4.187 1.932.654 1.095 1.095.814 3.664-.897 6.667a25.62 25.62 0 00-2.658-3.113 25.578 25.578 0 00-3.117-2.663 10.165 10.165 0 014.74-1.545zm-14.035.653a2.656 2.656 0 011.932-.653c1.684.115 3.313.647 4.74 1.549a26.033 26.033 0 00-5.775 5.773c-1.71-3.004-1.992-5.573-.897-6.67zm0 15.966c-1.095-1.096-.814-3.665.897-6.668a25.608 25.608 0 002.659 3.113 25.598 25.598 0 003.111 2.659c-3.001 1.71-5.572 1.992-6.667.896zm4.573-4.574a24.088 24.088 0 01-2.85-3.41 25.1 25.1 0 016.26-6.26 24.15 24.15 0 013.411 2.854 24.127 24.127 0 012.85 3.407 24.104 24.104 0 01-2.852 3.41A24.121 24.121 0 0111 17.257a24.113 24.113 0 01-3.41-2.851zm11.394 4.574c-1.096 1.097-3.666.815-6.667-.896a25.59 25.59 0 003.111-2.659 25.602 25.602 0 002.659-3.113c1.711 3.002 1.992 5.572.897 6.668z" />
      <path d="M11 13.874a2.879 2.879 0 100-5.757 2.879 2.879 0 000 5.757z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          d="M0 0H20.129V20.129H0z"
          transform="translate(.935 .936)"
        />
      </clipPath>
    </defs>
  </svg>
)
