import React from "react"

export const Smartphone = () => (
  <svg width="22" height="22" fill="none">
    <g clipPath="url(#clip0)">
      <path
        fill="#000"
        d="M14.66.936H7.34a2.288 2.288 0 00-2.287 2.287v15.554a2.288 2.288 0 002.287 2.288h7.32a2.288 2.288 0 002.287-2.288V3.223A2.288 2.288 0 0014.66.936zM11 20.15a1.37 1.37 0 01-1.372-1.373c0-.76.613-1.372 1.372-1.372.76 0 1.373.613 1.373 1.372A1.37 1.37 0 0111 20.15zm4.117-3.66H6.883V3.68h8.234v12.81z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          d="M0 0h20.129v20.129H0z"
          transform="translate(.935 .936)"
        />
      </clipPath>
    </defs>
  </svg>
)
