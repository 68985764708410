import React from "react"

export const ServerStorage = () => (
  <svg width="20" height="20" fill="none">
    <g fill="#000" clipPath="url(#clip0)">
      <path d="M10 5.484c4.988 0 9.032-1.01 9.032-2.258 0-1.247-4.044-2.258-9.032-2.258S.968 1.978.968 3.226c0 1.247 4.044 2.258 9.032 2.258z" />
      <path d="M10 6.99C5.012 6.99.968 5.978.968 4.73v3.011C.968 8.99 5.012 10 10 10s9.032-1.01 9.032-2.258v-3.01c0 1.247-4.044 2.257-9.032 2.257z" />
      <path d="M10 11.506c-4.988 0-9.032-1.011-9.032-2.258v3.01c0 1.247 4.044 2.258 9.032 2.258s9.032-1.01 9.032-2.258v-3.01c0 1.247-4.044 2.258-9.032 2.258z" />
      <path d="M10 16.022c-4.988 0-9.032-1.011-9.032-2.258v3.01c0 1.248 4.044 2.258 9.032 2.258s9.032-1.01 9.032-2.258v-3.01c0 1.247-4.044 2.258-9.032 2.258z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          d="M0 0h18.064v18.064H0z"
          transform="translate(.968 .968)"
        />
      </clipPath>
    </defs>
  </svg>
)
