import React from "react"
import { AllProjects as AllProjectsPage } from "../components/AllProjects/AllProjects"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO"

const AllProjects: React.FC = () => (
  <Layout>
    <div>
      <SEO title="Remote Development Teams and Technology Stack" />
      <AllProjectsPage />
    </div>
  </Layout>
)
export default AllProjects
