import React from "react"

export const Security = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_2223:1553)">
      <path d="M18.106 3.31022C18.0727 3.07065 17.9003 2.87359 17.6673 2.80882L10.1994 0.732002C10.0929 0.70237 9.98048 0.70237 9.87394 0.732002L2.40605 2.80882C2.17305 2.87359 2.00068 3.07057 1.96732 3.31022C1.92401 3.62168 0.935875 10.9807 3.47037 14.6417C6.00188 18.2982 9.73638 19.2353 9.8941 19.2734C9.94097 19.2847 9.98874 19.2903 10.0367 19.2903C10.0846 19.2903 10.1324 19.2846 10.1792 19.2734C10.337 19.2353 14.0715 18.2982 16.603 14.6417C19.1375 10.9808 18.1493 3.62176 18.106 3.31022ZM14.8525 7.60652L9.75865 12.7004C9.64012 12.8189 9.48467 12.8782 9.32931 12.8782C9.17394 12.8782 9.0185 12.819 8.89997 12.7004L5.75049 9.55089C5.63658 9.43706 5.57262 9.28258 5.57262 9.12155C5.57262 8.96052 5.63666 8.80604 5.75049 8.69221L6.37584 8.06687C6.61297 7.82981 6.99746 7.82973 7.23451 8.06687L9.32931 10.1617L13.3685 6.12241C13.4823 6.0085 13.6368 5.94454 13.7978 5.94454C13.9588 5.94454 14.1133 6.0085 14.2272 6.12241L14.8525 6.74776C15.0896 6.9849 15.0896 7.36938 14.8525 7.60652Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_2223:1553">
        <rect width="18.5806" height="18.5806" fill="white" transform="translate(0.709656 0.709732)"/>
      </clipPath>
    </defs>
  </svg>
)
