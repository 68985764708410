import React from "react"

export const Satellite = () => (
  <svg width="22" height="22" fill="none">
    <path
      fill="#000"
      d="M17.963 3.946a7.448 7.448 0 00-5.302-2.193v1.364c1.64 0 3.182.637 4.338 1.793a6.094 6.094 0 011.793 4.338h1.364a7.448 7.448 0 00-2.193-5.302z"
    />
    <path
      fill="#000"
      d="M16.034 5.875a4.737 4.737 0 00-3.374-1.396v1.363c.912 0 1.768.354 2.41.997.643.642.997 1.498.997 2.41h1.363a4.737 4.737 0 00-1.396-3.374zm-5.301 6.265l1.061-1.061c.276.13.567.213.866.213.524 0 1.048-.199 1.446-.598a2.047 2.047 0 000-2.891 2.047 2.047 0 00-2.891 0c-.627.626-.743 1.553-.385 2.312L9.77 11.176 3.977 5.385l-.468.656c-2.304 3.232-2.14 7.652.334 10.71 2.942 3.618 8.227 4.356 12.025 1.649l.656-.468-5.792-5.792z"
    />
  </svg>
)
