import React from "react"

export const Gamepad = () => (
  <svg width="22" height="22" fill="none">
    <g clipPath="url(#clip0)">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M19.648 8.157l1.35 5.734a2.482 2.482 0 01-2.416 3.05 1.958 1.958 0 01-1.67-.946l-1.04-1.732a.994.994 0 00-.848-.48H6.976a.994.994 0 00-.848.48l-1.04 1.732c-.35.583-.989.946-1.67.946a2.482 2.482 0 01-2.416-3.05l1.35-5.734c.496-2.11 2.368-3.133 3.955-3.133h9.386c2.168 0 3.592 1.589 3.955 3.133zM7.8 9.993h.674a.59.59 0 000-1.18H7.8V8.14a.59.59 0 00-1.18 0v.674h-.673a.59.59 0 000 1.18h.674v.673a.59.59 0 001.18 0v-.674zm5.91-1.444a1.277 1.277 0 101.806 1.806 1.277 1.277 0 00-1.806-1.806z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          d="M0 0h20.129v20.129H0z"
          transform="translate(.935 .936)"
        />
      </clipPath>
    </defs>
  </svg>
)
