import React from "react"

export const Folder = () => (
  <svg width="20" height="16" fill="none">
    <path
      d="M5.60444 7.83224c.69782-.32173 1.36531-.48267 2.00212-.48267h8.45614V5.72336c0-.62335-.2236-1.15888-.671-1.6061-.4473-.44718-.9823-.67073-1.6058-.67073H8.25696v-.32522c0-.62335-.22355-1.15864-.67073-1.60571-.44721-.44718-.98249-.670873-1.60585-.670873h-3.2522c-.62335 0-1.15864.223663-1.60585.670873-.447213.44711-.670731.98239-.670731 1.60575v9.75675c0 .0272.001744.0696.005126.1273.003346.0575.005126.0999.005126.127l.050763-.0607L3.93768 9.04673c.41329-.4879.9689-.89283 1.66676-1.21449Z"
      fill="#000"
    />
    <path
      d="M19.2791 8.78245c-.1794-.08821-.3843-.13243-.6148-.13243H7.60654c-.44718 0-.93334.12036-1.45837.36097-.52506.24085-.93333.53346-1.22459.87929L1.50869 13.9149c-.2101.2372-.31515.4608-.31515.671 0 .2032.08982.3489.26933.4369.17952.0882.38446.1322.61488.1322H13.1358c.4471 0 .933-.1203 1.4582-.3608.5251-.2405.9332-.5335 1.2245-.8793l3.415-4.02462c.21-.23718.3149-.46059.3149-.67098.0001-.20315-.0896-.34875-.2693-.43685Z"
      fill="#000"
    />
  </svg>
)
