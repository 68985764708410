import React from "react"

export const WirelessSignal = () => (
  <svg width="22" height="22" fill="none">
    <g fill="#000" clipPath="url(#clip0)">
      <path d="M11.123 19.085a2.113 2.113 0 100-4.226 2.113 2.113 0 000 4.226zm6.03-7.71a8.46 8.46 0 00-2.711-1.825 8.465 8.465 0 00-3.319-.672A8.488 8.488 0 004.99 11.48l2.601 2.515a4.867 4.867 0 013.53-1.497c1.31 0 2.543.51 3.47 1.436l2.561-2.558z" />
      <path d="M11.123 3c-1.944 0-3.83.38-5.609 1.134A14.405 14.405 0 00.935 7.219l2.56 2.56a10.724 10.724 0 017.624-3.158c2.861-.001 5.556 1.107 7.59 3.12l2.547-2.571A14.315 14.315 0 0016.7 4.12 14.332 14.332 0 0011.123 3z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          d="M0 0h20.645v20.645H0z"
          transform="translate(.677 .678)"
        />
      </clipPath>
    </defs>
  </svg>
)
