import React from "react"

export const Box = () => (
  <svg width="20" height="20" fill="none">
    <g clipPath="url(#clip0)">
      <path
        fill="#000"
        d="M18.877 5.306v-.04c-.02-.039-.02-.078-.039-.117v-.02a.428.428 0 00-.079-.098l-.02-.02c-.019-.02-.058-.04-.078-.059l-.02-.02h-.019l-.02-.02L10.255.77a.626.626 0 00-.53 0L6.916 2.163l8.387 4.301.02.02c.02 0 .02.02.039.02.02.02.02.039.039.059v4.615c0 .079-.04.138-.098.177l-1.69.884c-.097.059-.215.02-.274-.079-.02-.02-.02-.059-.02-.098V7.604l-8.485-4.38-.02-.02-3.397 1.69-.02.019h-.02l-.019.02c-.02.02-.059.039-.078.058l-.02.02c-.04.04-.059.079-.098.118v.02c-.02.039-.04.078-.04.117v.04c0 .039-.02.059-.02.098v9.172c0 .216.119.432.335.53l8.268 4.125a.54.54 0 00.393.039l.04-.02c.039 0 .058-.02.098-.039l8.327-4.124a.591.591 0 00.334-.53V5.403c-.02-.04-.02-.06-.02-.098z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          d="M0 0h18.58v18.58H0z"
          transform="translate(.71 .71)"
        />
      </clipPath>
    </defs>
  </svg>
)
