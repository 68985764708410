import React from "react"
import ProjectRow from "../ProjectRow"
import { rows } from "../rows"
import * as styles from "./ProjectsTable.module.scss"


export const ProjectsTable: React.FC = () => (
  <div className={styles.container}>
    <div className={styles.table}>
      {rows.map(row =>
        <ProjectRow
          icon={row.icon}
          title={row.title}
          technologyIcons={row.technologyIcons}
          key={row.title}
        />
      )}
    </div>
  </div>
)
