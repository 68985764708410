import React from "react"

export const Network = () => (
  <svg width="22" height="22" fill="none">
    <g clipPath="url(#clip0)">
      <path
        fill="#000"
        d="M19.042.42a2.283 2.283 0 00-2.004 3.367l-3.736 3.735a3.019 3.019 0 00-3.722 0L6.665 4.607a1.5 1.5 0 00.215-.767c0-.838-.681-1.52-1.52-1.52-.838 0-1.52.682-1.52 1.52s.682 1.52 1.52 1.52a1.5 1.5 0 00.767-.215l2.915 2.914a3.023 3.023 0 000 3.723L4.23 16.596a2.27 2.27 0 00-1.53-.594c-1.257 0-2.28 1.023-2.28 2.28s1.023 2.28 2.28 2.28a2.283 2.283 0 002.005-3.367L9.58 12.32c.42.326.927.545 1.481.615v3.861a2.282 2.282 0 00-1.9 2.246 2.283 2.283 0 002.28 2.28 2.283 2.283 0 002.28-2.28 2.282 2.282 0 00-1.9-2.246v-3.861a3.024 3.024 0 001.482-.615l2.915 2.914a1.5 1.5 0 00-.216.768c0 .838.682 1.52 1.52 1.52s1.52-.682 1.52-1.52-.682-1.52-1.52-1.52a1.5 1.5 0 00-.767.215l-2.915-2.914a3.022 3.022 0 000-3.723l3.673-3.673c.405.367.94.593 1.53.593a2.283 2.283 0 002.28-2.28 2.283 2.283 0 00-2.28-2.28z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          d="M0 0h20.903v20.903H0z"
          transform="translate(.42 .42)"
        />
      </clipPath>
    </defs>
  </svg>
)
