import React from "react"
import * as styles from "./ProjectIcon.module.scss"

interface ProjectIconProps {
  Image: React.FC
  text: string
}

export const ProjectIcon: React.FC<ProjectIconProps> = ({ Image, text }) => (
  <>
    <div className={styles.icon}>
      <Image />
    </div>
    <div className={styles.heading}>{text}</div>
  </>
)
