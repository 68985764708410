import * as icons from "../RemoteTeams/icons";
import {
    Atom,
    Box,
    Brain,
    Folder,
    Gamepad,
    Network,
    Satellite,
    Screen,
    Security,
    ServerStorage,
    Share,
    Smartphone,
    WirelessSignal
} from "./icons";
import { ProjectRowProps } from "./ProjectRow/ProjectRow";


export const rows: ProjectRowProps[] = [
    {
        icon: Atom,
        title: "Tasks Control and HW Administration for Quantum Computers",
        technologyIcons: [icons.java, icons.kotlin, icons.go, icons.react, icons.electron, icons.figma, icons.postgresql, icons.microservices, icons.consul, icons.grpc, icons.aws, icons.cicd]
    },
    {
        icon: Brain,
        title: "Network Anomalies Predictions ML",
        technologyIcons: [icons.python, icons.pandas, icons.scikit, icons.scipy, icons.docker, icons.postgresql, icons.mlflow]
    },
    {
        icon: ServerStorage,
        title: "Storage Monitoring System",
        technologyIcons: [icons.python, icons.angular, icons.react, icons.ml, icons.elasticsearch]
    },
    {
        icon: WirelessSignal,
        title: "MMWave wireless Management",
        technologyIcons: [icons.figma, icons.angular, icons.java]
    },
    {
        icon: Share,
        title: "SDN Controller",
        technologyIcons: [icons.java, icons.onap, icons.kubernetes]
    },
    {
        icon: Smartphone,
        title: "Mobile Applications",
        technologyIcons: [icons.flutter, icons.dart]
    },
    {
        icon: Box,
        title: "SDK Mission Critical PTT Calls",
        technologyIcons: [icons.cpp, icons.pjsip, icons.linux]
    },
    {
        icon: Satellite,
        title: "Telecom Network Discovery",
        technologyIcons: [icons.java, icons.python, icons.xquery, icons.postgresql, icons.dockerSwarm]
    },
    {
        icon: Network,
        title: "Network/Service Monitoring and Control System",
        technologyIcons: [icons.javaEE, icons.go, icons.timescale, icons.aws, icons.figma, icons.ml]
    },
    {
        icon: Security,
        title: "HomeLand Security",
        technologyIcons: [icons.python, icons.angular, icons.flask, icons.opencv, icons.docker]
    },
    {
        icon: Screen,
        title: "Screen Duplication Solutions",
        technologyIcons: [icons.react, icons.typescript, icons.webRTC, icons.grpc]
    },
    {
        icon: Folder,
        title: "Network Connectivity Open Source Project",
        technologyIcons: [icons.go, icons.node, icons.nats, icons.graphQL, icons.microservices, icons.docker, icons.kubernetes]
    },
    {
        icon: Gamepad,
        title: "Gaming Software",
        technologyIcons: [icons.java, icons.springBoot, icons.figma]
    }
]