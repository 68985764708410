import { navigate } from "gatsby"
import React, { useContext } from "react"
import BackButton from "../BackButton/BackButton"
import Button from "../Button"
import Notification from "../Notification"
import SectionHeader from "../SectionHeader"
import * as styles from "./AllProjects.module.scss"
import ProjectsTable from "./ProjectsTable"
import { remoteTeamsPageRoutesContext } from "../../data/context"

export const AllProjects: React.FC = () => {
  const routes = useContext(remoteTeamsPageRoutesContext)

  const vacanciesButton = (
    <Button
      text={"View vacancies"}
      color={"yellow"}
      onClick={() => navigate(routes.vacancies.to)}
    />
  )

  return (
    <>
      <div className={styles.container}>
        <SectionHeader main="Remote Development Teams" secondary="V4Teams" />
        <div className={styles.description}>
          <div>
            <h4 className={styles.descriptionText}>
              Our Remote Teams are developing cutting-edge SW products in
              cooperation
            </h4>
            <h4 className={styles.descriptionText}>
              with the top 50 Israeli technology companies.
            </h4>
          </div>
          <BackButton />
        </div>
        <ProjectsTable />
        <div className={styles.notification}>
          <Notification link={vacanciesButton} />
        </div>
      </div>
    </>
  )
}
