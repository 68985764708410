import React from "react"

export const Share = () => (
  <svg width="20" height="20" fill="none">
    <g fill="#000" clipPath="url(#clip0)">
      <path d="M18.124 3.678a2.62 2.62 0 11-5.241 0 2.62 2.62 0 015.241 0z" />
      <path d="M15.504 6.903a3.23 3.23 0 01-3.226-3.225A3.229 3.229 0 0115.504.452a3.229 3.229 0 013.225 3.226 3.229 3.229 0 01-3.226 3.225zm0-5.242a2.018 2.018 0 00-2.017 2.017c0 1.11.904 2.016 2.017 2.016a2.018 2.018 0 002.016-2.016 2.018 2.018 0 00-2.016-2.017zm2.62 14.92a2.62 2.62 0 11-5.241 0 2.62 2.62 0 015.241 0z" />
      <path d="M15.504 19.806a3.23 3.23 0 01-3.226-3.225 3.229 3.229 0 013.226-3.226 3.229 3.229 0 013.225 3.226 3.23 3.23 0 01-3.226 3.226zm0-5.242a2.018 2.018 0 00-2.017 2.017c0 1.11.904 2.016 2.017 2.016a2.018 2.018 0 002.016-2.016 2.018 2.018 0 00-2.016-2.016zm-8.67-4.435a2.621 2.621 0 11-5.242 0 2.621 2.621 0 015.242 0z" />
      <path d="M4.213 13.355A3.229 3.229 0 01.987 10.13a3.229 3.229 0 013.226-3.225 3.23 3.23 0 013.226 3.225 3.229 3.229 0 01-3.226 3.226zm0-5.242a2.018 2.018 0 00-2.016 2.016c0 1.112.904 2.016 2.016 2.016A2.018 2.018 0 006.23 10.13a2.018 2.018 0 00-2.016-2.016z" />
      <path d="M6.117 9.742a.805.805 0 01-.4-1.507L13.2 3.969a.805.805 0 011.1.301.808.808 0 01-.301 1.1L6.515 9.636a.801.801 0 01-.398.106zm7.483 6.653a.801.801 0 01-.399-.105l-7.483-4.266a.807.807 0 01.798-1.402L14 14.888a.807.807 0 01-.4 1.507z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          d="M0 0h19.355v19.355H0z"
          transform="translate(.194 .452)"
        />
      </clipPath>
    </defs>
  </svg>
)
